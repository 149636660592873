import React from "react"
import styled, { keyframes } from "styled-components"
import { useInView } from "react-intersection-observer"

interface StyledProps {
  inView: boolean
}

const DeviceMockup = () => {
  const [ref, inView] = useInView()

  return (
    <Wrapper ref={ref}>
      {/* <Blur />
      <Blur2 /> */}
      <Device3 inView={inView} src="/images/mockups/device6.webp" alt="device3" />
      <Device2 inView={inView} src="/images/mockups/device5.webp" alt="device2" />
      <Device1 inView={inView} src="/images/mockups/device4.webp" alt="device1" />
      <Pen inView={inView} src="/images/mockups/apple-pencil.webp" alt="pen" />
    </Wrapper>
  )
}

export default DeviceMockup

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
 100% {
    opacity: 1;
  }
`

const Wrapper = styled.div`
  position: relative;
  width: 587px;
  height: 700px;
  margin: 0 auto;
`

const Device1 = styled.img<StyledProps>`
  position: absolute;
  top: 0;
  left: 103px;
  max-width: 353px;
  margin: 0;
  opacity: 0;
  animation: ${fadeIn} 1.5s 0.5s linear forwards;
  animation-play-state: ${props => (props.inView ? "running" : "paused")};
`

const Device2 = styled.img<StyledProps>`
  position: absolute;
  top: 140px;
  left: 295px;
  max-width: 273px;
  margin: 0;
  opacity: 0;
  animation: ${fadeIn} 1.5s 1s linear forwards;
  animation-play-state: ${props => (props.inView ? "running" : "paused")};
`

const Device3 = styled.img<StyledProps>`
  position: absolute;
  top: 268px;
  left: 44px;
  max-width: 543px;
  margin: 0;
  opacity: 0;
  animation: ${fadeIn} 1.5s 1.5s linear forwards;
  animation-play-state: ${props => (props.inView ? "running" : "paused")};
`

const Pen = styled.img<StyledProps>`
  position: absolute;
  top: 266px;
  left: 0;
  max-width: 200px;
  margin: 0;
  opacity: 0;
  animation: ${fadeIn} 1.5s 2s linear forwards;
  animation-play-state: ${props => (props.inView ? "running" : "paused")};
`

// const Blur = styled.div`
//   position: absolute;
//   top: 100px;
//   width: 425px;
//   height: 425px;
//   background: conic-gradient(
//     from 193.61deg at 46.18% 51.15%,
//     #fa8792 -54.94deg,
//     #561bbe 28.29deg,
//     #70e6fb 157.82deg,
//     #fbf8b3 220.83deg,
//     #fa8792 305.06deg,
//     #561bbe 388.29deg
//   );
//   filter: blur(100px);
// `

// const Blur2 = styled.div`
//   position: absolute;
//   top: 100px;
//   width: 612px;
//   height: 612px;
//   background: radial-gradient(
//     56.14% 56.14% at 80.45% 46.87%,
//     rgba(32, 242, 255, 0.5) 0%,
//     rgba(198, 82, 255, 0.5) 97.81%
//   );
//   filter: blur(100px);
// `
