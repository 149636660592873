import React from "react"
import styled from "styled-components"
import LazyLoad from "react-lazyload"
import useWindowSize from "../../utils/hooks/useWindowSize"

import DeviceMockup from "./DeviceMockup"

interface StyledProps {
  width: number
}

const DeviceMockupSection = () => {
  const { width } = useWindowSize()

  return (
    <Wrapper>
      <LazyLoad height={700}>
        <MockupWrapper width={width}>
          <DeviceMockup />
        </MockupWrapper>
      </LazyLoad>
    </Wrapper>
  )
}

export default DeviceMockupSection

const Wrapper = styled.div`
  position: relative;
  margin: 0px auto;
  width: 100%;
  overflow: hidden;
`

const MockupWrapper = styled.div<StyledProps>`
  @media (max-width: 630px) {
    transform-origin: top left;
    transform: scale(${props => props.width / 630});
    height: ${props => (props.width / 630) * 700}px;
  }
`
